import * as React from 'react';

import { cn } from '@cardo/lib';

type BadgeProps = {
  children: React.ReactNode;
  color?: 'purple' | 'transparent' | 'red' | 'yellow' | 'blue';
  className?: string;
};

export function Badge({ children, color = 'purple', className }: BadgeProps) {
  return (
    <div
      className={cn(
        'h-fit w-fit whitespace-nowrap rounded border px-1.5 py-0.5 text-xs',
        {
          'bg-theme-purple': color === 'purple',
          'text-theme-blue-darkest border bg-transparent':
            color === 'transparent',
          'bg-rose-600': color === 'red',
          'text-theme-blue-darkest bg-yellow-400': color === 'yellow',
          'text-white': color === 'purple' || color === 'red',
          'bg-blue-900 text-white': color === 'blue',
        },
        className
      )}
    >
      {children}
    </div>
  );
}
